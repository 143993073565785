import React from 'react'
import GlobalStyle from '../styles/global'
import SEO from './SEO'
import "@fontsource/cantata-one"
import 'fontsource-open-sans/latin.css'

const Layout = () => (
  <>
    <SEO />
    <GlobalStyle />
  </>
)

export default Layout
