import styled from 'styled-components'
import tw from 'tailwind.macro'

export const Input = styled.input`
  ${tw`text-base lg:text-xl font-sans text-black mt-8 xxl:w-3/4`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`

export const Label = styled.label`
  ${tw`text-base lg:text-xl font-sans text-white mt-8 xxl:w-1/4 mr-4`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`
export const InputContainer = styled.div`
  ${tw`text-base lg:text-xl font-sans text-white w-1/2 flex justify-between`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`
