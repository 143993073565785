const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/ozcalulators', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Oz Calculators', // Navigation and Site Title
  siteTitleAlt: 'Oz Calculators', // Alternative Site title for SEO
  siteTitleShort: 'Oz Calculators', // short_name for manifest
  siteHeadline: 'Oz Calculators - Calculators for Down Under', // Headline for schema.org JSONLD
  siteUrl: 'https://www.ozcalculators.com/', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Oz Calculators - Calculators for Down Under',
  author: 'Oz Calculators', // Author for schema.org JSONLD

  googleAnalyticsID: 'G-DDQGJMNPD5',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
