import styled from 'styled-components'
import tw from 'tailwind.macro'

export const Text = styled.p`
  ${tw`text-base lg:text-xl font-sans text-white mt-4 xxl:w-3/4`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`

export const Disclaimer = styled.p`
  ${tw`text-xs font-sans text-white mt-4 xxl:w-3/4`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`
