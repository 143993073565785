import styled from 'styled-components'
import tw from 'tailwind.macro'

const defaultButtonColour = '#0066CC'

export const Button = styled.button`
  ${tw`text-base lg:text-xl font-sans text-white mt-8`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  background: ${props => props.bg || defaultButtonColour};
  border: 0;
  padding: 8px 16px;
`
